import { Link } from 'gatsby'
import { FC } from 'react'
import styled from 'styled-components'

import { Title } from '../../common/styles'
import Layout from '../../layouts'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const SuccessTitle = styled(Title)`
  margin-top: 28px;
`

const Message = styled.div`
  margin-bottom: 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Text = styled.p`
  line-height: 21px;
  text-align: center;
`

const SuccessPage: FC = () => {
  return (
    <Layout smallNavBar>
      <Container>
        <SuccessTitle>Merci pour votre commande !</SuccessTitle>
        <Message>
          <Text>
            Vous recevrez un email de confirmation dans quelques instants.
            <br />
            Si vous n'avez pas reçu cet email, vérifiez votre dossier spam.
            <br />
            Si vous ne le trouvez pas d'ici quelques minutes,{' '}
            <Link to='/contact/'>contactez-moi ici</Link>.
            <br />À très vite !
          </Text>
        </Message>
        <Link to='/'>Retourner à l'accueil</Link>
      </Container>
    </Layout>
  )
}

export default SuccessPage
